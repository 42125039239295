import dynamic from 'next/dynamic';
import { createContext, ReactElement, useState } from 'react';

const AppInsightsContext = createContext(null);

const AppInsightsSdk = dynamic(() => import('./Sdk'), {
  ssr: false,
});

const AppInsightsContextProvider = ({
  children,
}: {
  children: ReactElement;
}) => {
  const instrumentationKey =
    process.env.NEXT_PUBLIC_APPINSIGHTS_INSTRUMENTATIONKEY;

  const [instance, setInstance] = useState(null);

  return (
    <AppInsightsContext.Provider value={instance}>
      {instrumentationKey && (
        <AppInsightsSdk
          instance={instance}
          instrumentationKey={instrumentationKey}
          setInstance={setInstance}
        />
      )}

      {children}
    </AppInsightsContext.Provider>
  );
};

export { AppInsightsContext, AppInsightsContextProvider };
