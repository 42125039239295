import { HomePageType } from 'pages';
import { SlugPageType } from 'types';
import {
  GqlContentPageRecord,
  GqlQuery,
  GqlSuiteVersionRecord,
} from 'types/dato-types';
import { AllSuiteVersions, SuiteVersionRecord } from 'types/versions-types';

export type GqlDocPageRecord = GqlContentPageRecord & {
  suiteVersion: GqlSuiteVersionRecord | SuiteVersionRecord;
};

const API_URL = 'https://graphql.datocms.com';
const API_TOKEN = process.env.DATOCMS_API_TOKEN;

const META_TAGS_FRAGMENT = `
  attributes
  content
  tag
`;

const linkRecordFragment = `
  _modelApiKey
  id
  source
`;

const navLinkRecordFragment = `
  ... on ExternalLinkRecord {
    ${linkRecordFragment}
  }
  ... on InternalLinkRecord {
    ${linkRecordFragment}
  }
`;

const NAV_LINKS_FRAGMENT = `
   id
   groupNavLink {
     _modelApiKey
     id
     linkName
     linkType {
       ${navLinkRecordFragment}
     }
      openInNewTab
   }
   menu {
     id
     _modelApiKey
     linkName
     linkType {
       ${navLinkRecordFragment}
      }
     openInNewTab
   }
`;

const simpleLinkRecordFragment = `
  ...on SimpleLinkRecord {
   id
   linkName
   linkUrl
   openInNewTab
 }
 `;
const sidebarSuiteFragment = `
 ...on SidebarSuiteGroupRecord{
     sidebarSuiteId
     sidebarSuiteLink
     sidebarTitle
     pageLinks {
      ${simpleLinkRecordFragment}
     }
     accordions {
       accordionGroupTitle
       id
       accordionGroupLinks {
         ${simpleLinkRecordFragment}
       }
     }
     externalLinks {
       ${simpleLinkRecordFragment}
     }
   }
`;

async function fetchAPI(
  query: string,
  {
    variables,
    preview,
  }: {
    variables?: {
      slug?: string;
      sidebarSuiteId?: string;
      label?: string;
      suiteVersionId?: string;
    };
    preview?: boolean;
  } = {},
) {
  const res = await fetch(API_URL + (preview ? '/preview' : ''), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${API_TOKEN}`,
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  });

  const json = await res.json();
  if (json.errors) {
    // error returns in this format: { data: [ { id: '5413bc', type: 'api_error', attributes: [Object] } ] }
    console.error(json.data[0].attributes);
    throw new Error('Failed to fetch API');
  }

  return json.data;
}

export async function getAllPostsForHome(
  preview: boolean,
): Promise<HomePageType> {
  const heroRecordFragment = `
    ... on HeroRecord {
      id
      _modelApiKey
      title
      content
      callToActionLink
      callToActionLabel
      image {
        url
        alt
        width
        height
      }
    }
  `;
  const doubleCardRecordFragment = `
    ... on DoubleCardSectionRecord {
      id
      _modelApiKey
      cardItems {
        title
        content
        callToActionLink
        callToActionLabel
        image {
          url
          alt
          width
          height
        }
      }
    }
  `;
  const tripleCardRecordFragment = `
    ... on TripleCardSectionRecord {
      id
      heading
      _modelApiKey
      cardItems {
        title
        content
        callToActionLink
        callToActionLabel
      }
    }
  `;
  const textImageRecordFragment = `
    ... on TextImageSectionRecord {
      id
      heading
      _modelApiKey
      callToActionLabel
      callToActionLink
      content
      image {
        url
        alt
        width
        height
      }
      imageToRight
    }
  `;
  const productTileRecordFragment = `
    ... on ProductTileSectionRecord {
      id
      _modelApiKey
      heading
      cardItems {
        title
        id
        content
        icon {
          url
          alt
        }
      }
    }
  `;
  const query = `
    {
      globalSEO: _site {
        favicon: faviconMetaTags {
          ${META_TAGS_FRAGMENT}
        }
      }
      homePage {
        title
        pageSEO: _seoMetaTags {
          ${META_TAGS_FRAGMENT}
        }
        metadataSchema
        content {
          ${heroRecordFragment}
          ${doubleCardRecordFragment}
          ${tripleCardRecordFragment}
          ${textImageRecordFragment}
          ${productTileRecordFragment}
        }
      }
    }
  `;
  const data = await fetchAPI(query, { preview });

  return data;
}

export async function getContentPageBySlug(
  slug: string[],
  contentPageId?: string,
): Promise<SlugPageType> {
  const admonitionRecordFragment = `
    ... on AdmonitionRecord {
      id
      _modelApiKey
      admonitionType
      heading
      text
    }
  `;
  const downloadButtonFragment = `
    ... on DownloadButtonRecord {
         id
        _modelApiKey
        title
        url
      }
  `;
  const downloadModuleFragment = `
    ... on DownloadModuleRecord {
        id
        heading
        _modelApiKey
        description
        fileLink {
          id
          _modelApiKey
          url
        }
      }
  `;
  const tableRecordFragment = `
    ... on TableRecord {
      id
      _modelApiKey
      table
    }
  `;
  const imageRecordFragment = `
    ... on ImageRecord {
      id
      _modelApiKey
      allowClickToEnlarge
      image {
        url
        alt
        width
        height
      }
    }
  `;
  const featureListRecordFragment = `
    ... on FeatureListRecord {
      id
      _modelApiKey
      heading
      listItems {
        text
        id
      }
    }
  `;
  const overviewListRecordFragment = `
    ... on OverviewListRecord {
      id
      title
      _modelApiKey
      listItems {
        label
        id
      }
    }
  `;
  const buttonBarRecordFragment = `
    ... on ButtonBarRecord {
      id
      _modelApiKey
      buttonBar {
        ... on ButtonPrimaryRecord {
          id
          buttonType
          label
          link
        }
        ... on ButtonSecondaryRecord {
          id
          buttonType
          label
          link
        }
      }
    }
  `;
  const markdownRecordFragment = `
    ... on MarkdownRecord {
      id
      _modelApiKey
      markdown(markdown: false)
    }
  `;
  const structuredTextRecordFragment = `
    ... on StructuredTextRecord {
    id
    _modelApiKey
    structuredText {
      value
      blocks {
        ${tableRecordFragment}
        ${admonitionRecordFragment}
        ${featureListRecordFragment}
        ${overviewListRecordFragment}
        ${buttonBarRecordFragment}
        ${imageRecordFragment}
        ${downloadButtonFragment}
        ${downloadModuleFragment}
      }
    }
    }
  `;
  const idFilter = contentPageId ? `, id: {eq: "${contentPageId}"}` : '';

  const query = `
    query PageBySlug($slug: String) {
      globalSEO: _site {
        favicon: faviconMetaTags {
          ${META_TAGS_FRAGMENT}
        }
      }
      contentPage(filter: {slug: {eq: $slug}${idFilter}}) {
        id
        slug
        title
        pageSEO: _seoMetaTags {
          ${META_TAGS_FRAGMENT}
        }
        metadataSchema
        showTableOfContent
        showBackButton
        content {
          ${admonitionRecordFragment}
          ${structuredTextRecordFragment}
          ${markdownRecordFragment}
          ${downloadModuleFragment}
        }
        suiteSidebar {
          ${sidebarSuiteFragment}
        }
        suiteVersion {
          latest
          label
          value
          suite {
            label
            tagId
          }
        }
      }
      allUploads(filter: {type: {neq: image}, inUse: { eq: true }}) {
        id
        url
        filename
        size
      }
    }`;

  const data = await fetchAPI(query, {
    variables: {
      slug: slug.join('/'),
    },
  });

  return data;
}

export async function getAllContentPagesWithSlug(): Promise<
  GqlContentPageRecord[]
> {
  const data = await fetchAPI(`
    {
      allContentPages(first: "100") {
        id
        slug
        suite {
          id
          label
        }
        title
        metadataTags {
          description
        }
      }
    }
  `);

  return data?.allContentPages;
}

export async function getAllDocPages(): Promise<GqlDocPageRecord[]> {
  const data = await fetchAPI(`
    query DocPagesQuery {
      allContentPages(filter: {title: {eq: "Documentation"}, parent: {exists: false}}) {
        children {
          slug
          title
          suiteVersion {
            id
            label
            value
            latest
            suite {
              id
              label
              tagId
            }
          }
          children {
            slug
            title
            children {
              slug
              title
            }
          }
        }
      }
    }
  `);

  if (data?.allContentPages && data?.allContentPages.length > 0)
    return data.allContentPages[0].children;

  return [];
}

export async function getSuitePages(
  suiteVersionId: string,
): Promise<GqlDocPageRecord> {
  const data = await fetchAPI(
    `
    query SuitePagesQuery {
      contentPage(filter: {suiteVersion: {eq: "${suiteVersionId}"}}) {
        slug
        title
        suiteVersion {
          id
          label
          value
          latest
          suite {
            id
            label
            tagId
          }
        }
        suiteSidebar {
          ${sidebarSuiteFragment}
        }
        children {
          id
          slug
          title
          children {
            id
            slug
            title
          }
        }
      }
    }
    `,
  );

  return data?.contentPage;
}

export async function getSuitePagesById(id: string): Promise<GqlDocPageRecord> {
  const data = await fetchAPI(
    `
    query SuitePagesQuery {
      contentPage(filter: {id: {eq: "${id}"}}) {
        slug
        title
        suiteVersion {
          id
          label
          value
          latest
          suite {
            id
            label
            tagId
          }
        }
        suiteSidebar {
          ${sidebarSuiteFragment}
        }
        children {
          id
          slug
          title
          children {
            id
            slug
            title
          }
        }
      }
    }
    `,
  );

  return data?.contentPage;
}

export async function getTreeBySuiteSlug(slug: string) {
  // slug should always be "docs/{tagId}" e.g. docs/payments
  const data = await fetchAPI(
    `
    query SuitePagesQuery {
      contentPage(filter: {slug: {eq: "${slug}"}}) {
        parent {
          id
          parent {
            id
            title
          }
        }
      }
    }
    `,
  );

  const parentId = data?.contentPage?.parent?.id;

  if (parentId) {
    return getSuitePagesById(parentId);
  }

  return null;
}

export async function getAppNavigation(): Promise<
  Pick<GqlQuery, 'header' | 'footer'>
> {
  const data = await fetchAPI(`
    {
      header {
        headerLinks {
          ${NAV_LINKS_FRAGMENT}
        }
      }
      footer {
        footerLinks {
          ${NAV_LINKS_FRAGMENT}
        }
      }
    }
  `);

  return data;
}

export async function getContentVersion(): Promise<AllSuiteVersions> {
  const data: Pick<GqlQuery, 'allSuiteVersions'> = await fetchAPI(`
  {
    allSuiteVersions(orderBy: value_DESC) {
      id
      label
      value
      latest
      suite {
        label
        tagId
      }
      urlReference
      mediaReference {
        url
      }
    }
  }
  `);

  const allSuiteVersions = data?.allSuiteVersions?.reduce(
    (value: AllSuiteVersions, suiteVersion: GqlSuiteVersionRecord) => {
      const suiteTag = suiteVersion.suite?.tagId;

      // skip versions without suites
      if (!suiteTag) return value;

      if (!value[suiteTag])
        return {
          ...value,
          [suiteTag]: [{ ...suiteVersion, latest: true } as SuiteVersionRecord],
        };

      // ensure we don't have 2 versions set to latest in the current suite
      // if there is no latest, choose the first one (they are in desc order)
      const latest =
        value[suiteTag].find((version: SuiteVersionRecord) => version.latest) ||
        value[suiteTag][0];

      const updated = {
        ...value,
        [suiteTag]: [
          ...value[suiteTag],
          // Since versions are ordered by value_DESC we know the first version that
          // has latest=true that we encounter is the correct latest, and all subsequent
          // should have latest=false
          (latest
            ? { ...suiteVersion, latest: false }
            : suiteVersion) as SuiteVersionRecord,
        ],
      };

      return updated;
    },
    {},
  );

  return allSuiteVersions || {};
}
