import { notEmpty } from 'lib/utils';

import { SuiteVersionRecord } from '../../../types';

export const modifyVersionsRecordsBySuite = (
  list: Array<SuiteVersionRecord>,
) => {
  const map: { [suiteTag: string]: Array<SuiteVersionRecord> } = {};

  list?.forEach((val) => {
    const suiteTag = String(val?.suite?.tagId);
    if (!map[suiteTag]) {
      map[suiteTag] = [val];
    } else {
      map[suiteTag].push(val);
    }
  });

  return map;
};

const isVersionPattern = (input: string): boolean => {
  // check input string follows the pattern of `v${version_number}`, i.e. `v2`
  const regex = /^v\d$/i;

  return regex.test(input);
};

const getVersionNumberFromLabel = (label: string): number =>
  Number(label?.slice(1));

const getVersionLabelFromPath = (
  path: string,
  suiteVersions: SuiteVersionRecord[],
) => {
  const slug = path.slice(1).split('/');

  return slug.length > 1 && isVersionPattern(slug[2])
    ? slug[2]
    : suiteVersions[0]?.label;
};

export const getLatestVersion = (
  path: string,
  suiteVersions: SuiteVersionRecord[],
) => {
  const versionNums = suiteVersions?.map((o: { label: string }) =>
    getVersionNumberFromLabel(o.label),
  );
  let versionIndex = -1;
  const num = versionNums && Math.max(...versionNums);
  const suite = suiteVersions?.find((o: { label: string }, index) => {
    versionIndex = index;

    return o.label === `v${num}`;
  });

  return { num, suite, versionIndex };
};

export const getCurrentVersion = (
  path: string,
  suiteVersions: SuiteVersionRecord[] = [],
) => {
  const versionLabel = getVersionLabelFromPath(path, suiteVersions);

  return {
    num: getVersionNumberFromLabel(versionLabel),
    suite: suiteVersions?.find(
      (v: SuiteVersionRecord) => v.label === versionLabel,
    ),
  };
};

export const getVersionsParams = (
  path: string,
  suiteVersions: SuiteVersionRecord[],
) => {
  const { num: latestNum, suite: latestSuite } = getLatestVersion(
    path,
    suiteVersions,
  );
  const { num: currentNum, suite: currentSuite } = getCurrentVersion(
    path,
    suiteVersions,
  );

  return {
    current: {
      num: currentNum,
      suite: currentSuite,
    },
    latest: {
      num: latestNum,
      suite: latestSuite,
    },
  };
};

export const getVersionPath = (
  path: string,
  currentOption: SuiteVersionRecord,
  nextOption: SuiteVersionRecord,
) => {
  const [url, fragment] = path.split('#', 1);
  const slug = url.slice(1).split('/');

  const versionLabel = nextOption.latest ? [] : [nextOption.label];

  const prefixIdx = slug[0] === 'api-ref' ? 1 : 2;
  const nestedPath = [
    ...slug.slice(0, prefixIdx), // prefix either ['api-ref'] or ['docs', 'suite-name']
    ...versionLabel, // ['v1'] or []
    ...slug.slice(currentOption.latest ? prefixIdx : prefixIdx + 1), // rest of slug
  ];

  return `/${nestedPath.join('/')}${fragment ? `#${fragment}` : ''}`;
};

export const getVersionedLink = (
  link: string,
  suiteVersion?: SuiteVersionRecord,
): string => {
  // This is for use in components included in ContentPage
  // content
  //
  // If the link is to a docs page in the current suite,
  // then we want to ensure the URL is updated with the
  // correct version; already versioned URLs can be
  // returned unchanged

  const [docs, suite, version, ...rest] = link.split('/').filter(notEmpty);

  if (
    docs !== 'docs' ||
    !suiteVersion ||
    suiteVersion.latest ||
    suiteVersion.suite?.tagId !== suite ||
    /^v[0-9.]+$/.test(version)
  )
    return link;

  return `/${[docs, suite, suiteVersion.label, version, ...rest]
    .filter(notEmpty)
    .join('/')}`;
};
