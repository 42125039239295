import { useRouter } from 'next/router';
import {
  createContext,
  Dispatch,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

import {
  AllSuiteVersions,
  SuiteType,
  SuiteVersionRecord,
} from '../../../types';

import { getVersionPath } from './versionUtils';

export type SelectProviderStateType = {
  showAlert: boolean;
  showVersions: boolean;
  suite: Pick<SuiteType, any> | null;
  suiteVersions: {
    [key: string]: SuiteVersionRecord[];
  };
  latestVersionNum: number | null;
  currentV: SuiteVersionRecord;
};

export type VersionSelectProviderType = {
  versionState: {
    showVersions: boolean;
    latestVersionNum: number | null;
    suite: Pick<SuiteType, any> | null;
    currentV: SuiteVersionRecord;
    suiteVersions: {
      [key: string]: SuiteVersionRecord[];
    };
    current: {
      name: string;
      path: string;
    };
    latest: {
      name: string;
      path: string;
    };
    showAlert: boolean;
  };
  updateState: Dispatch<Partial<SelectProviderStateType>>;
};

export const VersionSelectContext =
  createContext<VersionSelectProviderType | null>(null);

export const VersionSelectProvider = ({
  children,
  contentVersions,
  suiteVersion,
}: {
  children: ReactNode;
  contentVersions?: AllSuiteVersions;
  suiteVersion?: SuiteVersionRecord;
}) => {
  const initialState = {
    showVersions: false,
    suite: null,
    suiteVersions: contentVersions,
    latestVersionNum: null,
    currentV: {
      label: '',
      suite: {
        label: '',
        tagId: '',
      },
      value: '',
    },
    showAlert: false,
  } as SelectProviderStateType;
  const [state, updateState] = useReducer(
    (
      prevState: SelectProviderStateType,
      updates: Partial<SelectProviderStateType>,
    ) => ({
      ...prevState,
      ...updates,
    }),
    initialState,
  );
  const router = useRouter();
  const { asPath } = router;
  // use `asPath` parameter of the router instead of `slug`
  // as the latter becomes undefined if the page does not exist and resolves to 404

  const currentSuiteVersions =
    contentVersions && suiteVersion?.suite?.tagId
      ? contentVersions[suiteVersion.suite.tagId]
      : [];
  const latest = currentSuiteVersions?.find((sv) => sv.latest);

  useEffect(() => {
    if (suiteVersion) {
      updateState({
        latestVersionNum: Number(latest?.value),
        suite: {
          ...suiteVersion.suite,
          ...{
            [suiteVersion.suite.tagId]: currentSuiteVersions,
          },
        },
        currentV: suiteVersion,
        showVersions: true,
        showAlert: !suiteVersion.latest,
      });
    } else {
      updateState({ showVersions: false, showAlert: false });
    }
  }, [suiteVersion, latest?.value]);

  const version = {
    current: {
      name: `${suiteVersion?.suite?.label} ${suiteVersion?.label}`,
      path: asPath,
    },
    latest: {
      name: latest?.label || '',
      path: latest ? getVersionPath(asPath, state.currentV, latest) : '',
    },
  };

  const value = useMemo(
    () => ({
      versionState: {
        showVersions: state.showVersions,
        suite: state.suite,
        suiteVersions: state.suiteVersions,
        latestVersionNum: state.latestVersionNum,
        showAlert: state.showAlert,
        currentV: state.currentV,
        ...version,
      },
      updateState,
    }),
    [state.showVersions, state.suite, state.showAlert],
  );

  return (
    <VersionSelectContext.Provider value={value}>
      {children}
    </VersionSelectContext.Provider>
  );
};

export const useVersionSelect = () => {
  const context = useContext(VersionSelectContext);
  if (!context) {
    throw new Error(
      'This component must be used within a <VersionSelectProvider> component.',
    );
  }

  const { versionState, updateState } = context;
  const closeAlert = useCallback(
    () => updateState({ showAlert: false }),
    [updateState],
  );

  return {
    versionState,
    closeAlert,
  };
};
