export const formatBytes = (bytes: number, decimals = 1) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))), 10);

  if (i === 0) return `${bytes} ${sizes[i]})`;

  return `${(bytes / 1024 ** i).toFixed(decimals)} ${sizes[i]}`;
};

export const getFileName = (url: any): string => {
  if (!url) return 'file';

  return url.split('/').slice().pop().split('?')[0];
};

export const notEmpty = <ValueType>(value: unknown): value is ValueType =>
  !!value;
