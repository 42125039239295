import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export type ModeType = 'light' | 'dark';

export type DarkModeToggleContextType = {
  toggleTheme: () => void;
  theme: ModeType;
};

export const DarkModeToggleContext =
  createContext<DarkModeToggleContextType | null>(null);

export const DarkModeToggleProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [theme, setTheme] =
    useState<DarkModeToggleContextType['theme']>('light');

  // Handle adding and removing mode class on the body and set the mode finally
  const handleSetTheme = (newTheme: ModeType) => {
    const bodyNode = document.body;

    bodyNode.classList.remove(theme);
    bodyNode.classList.add(newTheme);

    window.localStorage.setItem('theme', newTheme);

    setTheme(newTheme);
  };

  // Handle manual toggle
  const toggleTheme = (): void => {
    // eslint-disable-next-line no-unused-expressions
    theme === 'light' ? handleSetTheme('dark') : handleSetTheme('light');
  };

  // Set the initial mode based on the user's local or media settings
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const localTheme = window.localStorage.getItem('theme') as ModeType;
    const { matches: prefersDark } = window.matchMedia(
      '(prefers-color-scheme: dark)',
    );
    const { matches: prefersLight } = window.matchMedia(
      '(prefers-color-scheme: light)',
    );

    if (localTheme) {
      return handleSetTheme(localTheme);
    }
    if (prefersDark) {
      return handleSetTheme('dark');
    }
    if (prefersLight) {
      return handleSetTheme('light');
    }
  }, []);

  const value = useMemo(
    () => ({
      theme,
      toggleTheme,
    }),
    [theme],
  );

  return (
    <DarkModeToggleContext.Provider value={value}>
      {children}
    </DarkModeToggleContext.Provider>
  );
};

export const useDarkMode = () => {
  const context = useContext(DarkModeToggleContext);
  if (!context) {
    throw new Error(
      'This component must be used within a <DarkModeToggleProvider> component.',
    );
  }

  return context;
};
