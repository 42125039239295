import 'styles/index.css';

import type { NextPage } from 'next';
import App, { AppContext, AppProps } from 'next/app';
import type { ReactElement, ReactNode } from 'react';

import { AppInsightsContextProvider } from 'components/AppInsights/ContextProvider';
import { FooterType } from 'components/common/footer';
import { HeaderType } from 'components/common/header';

import { DarkModeToggleProvider } from '../components/common/dark-mode-toggle/dark-mode-toggle.provider';
import { VersionSelectProvider } from '../components/common/version-select/version-select.provider';
import { getAppNavigation, getContentVersion } from '../lib/api';
import { SuiteVersionRecord } from '../types';

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  header: HeaderType;
  footer: FooterType;
  contentVersions: { allSuiteVersions: Array<SuiteVersionRecord> };
};

export default function MyApp({
  Component,
  pageProps,
  header,
  footer,
  contentVersions,
}: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);

  return getLayout(
    <AppInsightsContextProvider>
      <DarkModeToggleProvider>
        <VersionSelectProvider
          contentVersions={contentVersions}
          suiteVersion={pageProps.suiteVersion}
        >
          <Component {...pageProps} footer={footer} header={header} />
        </VersionSelectProvider>
      </DarkModeToggleProvider>
    </AppInsightsContextProvider>,
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  const navProps = await getAppNavigation();
  // contentVersions will be availabie in pageProps in MyApp, so this might
  // not be necessary to do here:
  const contentVersions = await getContentVersion();
  const props = {
    header: navProps?.header || {},
    footer: navProps?.footer || {},
    contentVersions: contentVersions || {},
  };

  return {
    ...appProps,
    ...props,
  };
};
